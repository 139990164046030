<template>
  <ListPage
    :gridData="filteredData"
    :gridConfig="gridConfig"
    :filters="filters"
  />
</template>

<script>
import ListPage from "@/components/penal-retail-helpers/ListPage";
import { db, functions } from "@/firebase/firebaseConfig";

import usersFormConfig from "@/helpers/usersFormConfig";

let collectionName = "users";

export default {
  components: {
    ListPage,
  },
  computed: {
    filteredData() {
      let filteredData = this.data;
      if (this.$store.state.AppActiveUser.role !== "admin") {
        filteredData = filteredData.filter((u) => u.role !== "admin");
      }
      if (this.appliedFilters.role) {
        filteredData = filteredData.filter(
          (u) => u.role === this.appliedFilters.role.id
        );
      }
      if (this.appliedFilters.customer) {
        filteredData = filteredData.filter(
          (u) => u.customer && u.customer.id === this.appliedFilters.customer.id
        );
      }

      return filteredData;
    },
    filters() {
      let attributes = usersFormConfig(
        this,
        this.$store.state.AppActiveUser.role,
        true
      );

      let filters = [];
      filters.push({ size: "1/5", mobileSize: "1/2", search: true });
      filters.push({
        size: "1/5",
        mobileSize: "1/2",
        select: true,
        placeholder: `${collectionName}.placeholders.role`,
        label: "alias",
        clearable: true,
        resetOnOptionsChange: true,
        onChange: (e) => {
          this.updateFilters("role", e);
        },
        options: () =>
          attributes.find((a) => a.attribute === "role").extra.options,
      });
      filters.push({
        size: "1/5",
        mobileSize: "1/2",
        select: true,
        placeholder: `${collectionName}.placeholders.customer`,
        label: "alias",
        clearable: true,
        resetOnOptionsChange: true,
        onChange: (e) => {
          this.updateFilters("customer", e);
        },
        options: () => this.customers,
      });
      filters.push({
        size: "2/5",
        mobileSize: "1/2",
        sidebar: true,
        i18n: `${collectionName}.buttons.new`,
        extraClasses: "text-right",
        sidebarConfig: {
          title: `${collectionName}.buttons.new`,
          collectionName: collectionName,
          attributes: attributes,
          customCreate: this.onCreate,
          prevalidate: (/*data, collection, errors*/) => {
            //   if (data.establishments && data.establishments.length > 5) {
            //     errors.add({ field: "establishments", msg: this.$t(`Validations.${collectionName}.establishments.maxSize`) })
            //     return true;
            //   }

            return false;
          },
        },
      });
      return filters;
    },
  },
  watch: {
    customerId() {
      this.establishments = [];
      if (this.customerId) {
        this.$bind(
          "establishments",
          db
            .collection("establishments")
            .where("customer.id", "==", this.customerId)
            .where("deleted", "==", false)
            .orderBy("alias")
        );
      }
    },
  },
  data() {
    let columns = [];
    columns.push({
      headerName: this.$t(`${collectionName}.columns.email`),
      filter: false,
      width: 100,
      field: "email",
    });
    columns.push({
      headerName: this.$t(`${collectionName}.columns.alias`),
      filter: false,
      width: 100,
      field: "alias",
    });
    columns.push({
      headerName: this.$t(`${collectionName}.columns.role`),
      filter: false,
      width: 100,
      field: "role",
    });
    columns.push({
      headerName: this.$t(`${collectionName}.columns.customer`),
      filter: false,
      width: 100,
      field: "customer",
      valueGetter: (params) => {
        return params.data.customer ? params.data.customer.alias : "";
      },
    });

    return {
      customerId: null,
      customers: [],
      establishments: [],
      data: [],
      appliedFilters: {},
      gridConfig: {
        components: {},
        gridOptions: {},
        columnDefs: columns,
        defaultColDef: {
          sortable: true,
          resizable: true,
        },
        onRowClicked: this.onRowClicked,
      },
    };
  },
  created() {
    this.$bind(
      "data",
      db
        .collection(collectionName)
        .where("deleted", "==", false)
        .orderBy("alias"),
      { wait: true }
    );
    this.$bind(
      "customers",
      db.collection("customers").where("deleted", "==", false).orderBy("alias"),
      { wait: true }
    );
  },
  methods: {
    async onCreate(dataToSave) {
      this.$vs.loading();
      let createUser = functions.httpsCallable("createUser");

      try {
        await createUser(dataToSave);
        this.$vs.loading.close();
      } catch (e) {
        this.$vs.notify({
          title: "Error al crear el usuario",
          text: "No se pudo crear el usuario, revise que el usuario no exista y que el correo electrónico sea correcto",
          color: "danger",
        });
        this.$vs.loading.close();
        throw e;
      }
    },
    updateFilters(key, value) {
      let filters = this.appliedFilters;
      if (value !== null) {
        filters[key] = value;
      } else {
        delete filters[key];
      }
      this.appliedFilters = Object.assign({}, filters);
    },
    onRowClicked(params) {
      this.$router
        .push(`/settings/${collectionName}/${params.data.id}`)
        .catch(() => {});
    },
  },
};
</script>
